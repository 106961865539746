import { AnimatePresence, m } from 'framer-motion'
import { useState } from 'react'

import SignupForm from '@components/signupForm'
import { H2 } from '@components/typography'

const strings = {
  heading: 'Join the CAPSLOCK Universe',
  subheading:
    'Get access to our community, exclusive insights and premium promotions.',
  cta: {
    idle: 'Submit',
    loading: 'Subscribing you...',
  },
  subLine: 'No worries - we promise to keep it 100% CAPSLOCK.',
  confirmation: {
    heading: 'Stay ready.',
    subheading: 'We will be in touch soon with more details!',
  },
}

const fadeInOutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const NewsletterSignup = ({
  onSuccess,
}: {
  onSuccess?: (any: string) => any
}) => {
  const [success, setSuccess] = useState<null | boolean>(null)

  function handleSubmitSuccess(values) {
    onSuccess(values)
    setSuccess(true)
  }

  return (
    <m.div initial="initial" animate="animate" exit="exit">
      <AnimatePresence mode="wait" initial={false}>
        {!success ? (
          <>
            <H2 className="block text-center m-4">{strings.heading}</H2>
            <m.p className="text-center mx-auto m-4 text-xl">
              {strings.subheading}
            </m.p>

            <m.div className="relative mx-auto max-w-xl text-xl my-2 py-2 text-left">
              <SignupForm onSuccess={handleSubmitSuccess} />
            </m.div>
          </>
        ) : (
          <m.div
            key="success"
            className="h-[8rem] flex flex-col justify-center"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeInOutVariants}
          >
            <H2 className="block text-center m-4">
              {strings.confirmation.heading}
            </H2>
            <m.p className="mx-auto my-4 max-w-xl">
              {strings.confirmation.subheading}
            </m.p>
          </m.div>
        )}
      </AnimatePresence>
    </m.div>
  )
}

export default NewsletterSignup
