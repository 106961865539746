import { FormikErrors, useFormik } from 'formik'
import { AnimatePresence, m } from 'framer-motion'
import { useState } from 'react'
import * as Yup from 'yup'

import { EMAIL_FIELD } from '@lib/const'
import { registerEmailToLocalStorage } from '@lib/storage'

interface IErrors {
  [key: string]: any
}

const SignupForm = ({
  route = '/api/newsletter',
  ctaLabel = 'Subscribe',
  onSuccess,
  theme,
}: {
  route?: URL['pathname']
  ctaLabel?: string
  onSuccess?: (any: string) => any
  theme?: 'light'
}) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<null | boolean>(null)

  const schema = Yup.object({
    [EMAIL_FIELD]: Yup.string().required('Required'),
  })

  function validate(values) {
    const errors: IErrors = {}

    if (!values[EMAIL_FIELD]) {
      errors[EMAIL_FIELD] = 'Email is required'
    }

    return errors
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    // values, // use this if you want controlled components
    errors,
  } = useFormik({
    initialValues: {
      [EMAIL_FIELD]: null,
    },
    validate,
    validationSchema: schema,
    onSubmit: onSubmitAction,
  })

  function handleSubmitSuccess(values) {
    setLoading(false)
    setSuccess(true)
    if (onSuccess) onSuccess(values)
  }

  function handleSubmitFailure(r) {
    setLoading(false)
    setSuccess(false)
    errors[EMAIL_FIELD] = r.message
  }

  async function onSubmitAction(values, args) {
    setLoading(true)

    let response

    try {
      // if (!DEV) {
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify({
          [EMAIL_FIELD]: values[EMAIL_FIELD],
        }),
      }
      response = await fetch(route, fetchOptions).then(r => r.json())
      // } else {
      //   response = { ok: true }
      // }
      registerEmailToLocalStorage(values[EMAIL_FIELD])
    } catch {
      handleSubmitFailure(response)
    } finally {
      // console.log(response)
    }

    if (response.ok) handleSubmitSuccess(values)
    else handleSubmitFailure(response)
    // }
  }

  return (
    <m.div initial="initial" animate="animate" exit="exit">
      <AnimatePresence mode="wait" initial={false}>
        {!success ? (
          <m.form
            key="form"
            method="POST"
            acceptCharset="UTF-8"
            onSubmit={handleSubmit}
            className={`signup-form ${theme ? 'light' : ''} relative text-3xl`}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              exit: { opacity: 0 },
            }}
          >
            <m.input
              name={EMAIL_FIELD}
              type="email"
              required
              aria-required
              placeholder="Insert your email"
              onChange={handleChange}
              onBlur={handleBlur}
              className="block w-full my-2 mb-4 py-2 text-xl"
            />
            {touched[EMAIL_FIELD] && errors[EMAIL_FIELD] ? (
              <m.div className="relative my-4 text-red-500 text-lg">
                {errors[EMAIL_FIELD] as FormikErrors<string>}
              </m.div>
            ) : null}
            <m.button
              type="submit"
              disabled={loading}
              className={`${
                loading ? 'opacity-30 pointer-events-none' : ''
              } text-xl block w-full my-2 py-3`}
            >
              {loading ? 'Loading...' : ctaLabel}
            </m.button>
            <m.p className="opacity-70 text-xs leading-tight mt-2 pt-2">
              You will be subscribed to our newsletter and promotional emails.
              But hey - no worries. We promise to keep it 100% CAPSLOCK.
            </m.p>
          </m.form>
        ) : (
          <m.div
            key="success"
            className="h-[8rem] flex flex-col justify-center"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              exit: { opacity: 0 },
            }}
          >
            <m.p className="leading-none my-2 py-2">
              Thank you for supporting.
            </m.p>
            <m.p className="leading-none">
              We will be in touch soon with more details.
            </m.p>
          </m.div>
        )}
      </AnimatePresence>
    </m.div>
  )
}

export default SignupForm
