// import classNames from 'classnames'
import { HTMLMotionProps, m } from 'framer-motion'

interface IHeading extends HTMLMotionProps<'div'> {
  className?: string
  children: React.ReactNode
}

const H2_BASE: React.HTMLProps<HTMLElement>['className'] =
  'text-4xl md:text-7xl leading-none tracking-tight'

export const H2 = ({ children, className, ...rest }: IHeading) => {
  return (
    <m.h2 className={`${H2_BASE} ${className}`} {...rest}>
      {children}
    </m.h2>
  )
}

export const H2Heading = ({ children, ...props }: IHeading) => {
  return (
    <m.h2
      className="pl-2 p-4 text-4xl md:text-7xl leading-none tracking-tight pointer-events-none"
      {...props}
    >
      {children}
    </m.h2>
  )
}
