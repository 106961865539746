import { useCheckout } from 'lib-shopify'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Product } from 'shopify-buy'

import MainLayout from '@components/layout/main'
import GlobalMetaTags from '@components/metaTags'
import NewsletterSignup from '@components/newsletterSignup'
import { ProductTile } from '@components/views/shop'

import { getAllProducts } from '@lib/shopify'

const Shop = ({ products }) => {
  const { query } = useRouter()
  const { cart } = useCheckout()

  useEffect(() => {
    if (!Object.hasOwn(query, 'cart')) return
    if (query.cart === 'true') cart.toggle()
  }, [query])

  return (
    <>
      <GlobalMetaTags />
      <MainLayout>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 bg-black text-foreground">
          {products.map((product, i) => (
            <ProductTile
              priority={i < 2}
              key={`${product.id}`}
              data={product}
            />
          ))}
        </div>
        <div className="py-8 px-4 bg-black text-white">
          <NewsletterSignup />
        </div>
      </MainLayout>
    </>
  )
}

export default Shop

export async function getStaticProps() {
  const products: Product[] = await getAllProducts()

  return {
    props: {
      products,
    },
    revalidate: 120,
  }
}
