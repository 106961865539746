import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

const SIZES =
  '(max-width: 768px) 100vw, (max-width: 1024px) 50vw, (min-width: 1024px) 33vw'

const ProductTile = ({ data, priority = false }) => {
  const ref = useRef(null)
  const [hovering, setHovering] = useState(false)

  const frontImage = data.images.nodes[0]
  const backImage = data.images.nodes[1]

  function showBackImage() {
    setHovering(true)
  }

  function hideBackImage() {
    setHovering(false)
  }

  function handleHover(e: MouseEvent) {
    if (!ref.current) return

    if (ref.current.contains(e.target)) {
      showBackImage()
    } else {
      hideBackImage()
    }
  }

  useEffect(() => {
    window.addEventListener('pointermove', handleHover)
    return () => {
      window.removeEventListener('pointermove', handleHover)
    }
  }, [])

  return (
    <Link
      ref={ref}
      key={data.title}
      className="product-tile-border relative flex flex-col shrink grow border-b-px sm:border-r-px sm:[&:nth-child(2n)]:border-r-none lg:[&:nth-child(2n)]:border-r-px lg:[&:nth-child(3n)]:border-r-none bg-background text-foreground"
      // style={{ border: '4px solid red' }}
      href={`/shop/products/${data.handle}`}
      scroll={false}
    >
      <div className="relative">
        <div key={frontImage.url} className="relative bg-gray">
          <Image
            alt=""
            src={frontImage.url}
            width={frontImage.width}
            height={frontImage.height}
            sizes={SIZES}
            priority={priority}
          />
        </div>
        <div
          key={backImage.url}
          className="absolute inset-0 bg-gray"
          style={{ visibility: hovering ? 'visible' : 'hidden' }}
        >
          <Image
            alt=""
            src={backImage.url}
            width={backImage.width}
            height={backImage.height}
            sizes={SIZES}
          />
        </div>
      </div>
      <div className="flex flex-col p-2 text-2xl md:text-3xl grow justify-between">
        <div>{data.title}</div>
        <div>
          <span
            className={data.availableForSale ? '' : 'opacity-20 line-through'}
          >
            {data.priceRange.minVariantPrice.amount}{' '}
            {data.priceRange.minVariantPrice.currencyCode}
          </span>

          {!data.availableForSale && <span className="ml-2">Sold out</span>}
        </div>
      </div>
    </Link>
  )
}

export default ProductTile
